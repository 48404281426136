<template>

  <b-card-code
    title="Course Material"
  >
   <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-row class="mt-2">
        <b-col class="text-center" md="3" v-for="material in materials" :key="material.id">
            <a :href="material.pdfAttachmentUrl" target="blank" class=""><b-icon icon="book-half" font-scale="3"></b-icon></a> 
            <p><b>{{material.attachment_name}}</b></p>
       </b-col>
    </b-row>
  </b-overlay>
   </b-card-code>
</template>
<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BIcon, BInputGroup, BAlert, BInputGroupAppend, BCol, BAvatar,BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BOverlay, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CourseService from '@/services/course.service.js';

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    BIcon
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.courseId,
      logoImg: require('@/assets/images/pages/logo-1.png'),
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
      showOverlay: false,
      errors: '',
      materials: {}
    }
  },
  watch:{
        '$route'(to) {
            this.id = to.params.courseId;
        } 
    },
  methods: {
    getMaterial() {  
      this.showOverlay = true;
          CourseService.getLoggedInAttachment('pdf').then(response => {
            this.showOverlay = false;
            this.materials = response.data.data.attachments
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
    }
  },
  created() {
    this.getMaterial();
  }
}
</script>

