import axiosIns from '@/libs/axios.js';

class CourseService {
    getAll(){
        return axiosIns.get('/course').then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    listing(pageNumber=1){
        return axiosIns.get(`/course/true?page=${pageNumber}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    store(data){
        return axiosIns.post('/course/store', data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    edit(id, data){
        return axiosIns.post(`/course/${id}/edit`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    find(id) {
        return axiosIns.get(`/find-course/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    delete(id) {
        return axiosIns.post(`course/${id}/delete`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    storeMaterial(data) {
        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        }

        return axiosIns.post('course-attachment/store', data, config).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    storeVideo(data) {
        return axiosIns.post('course-attachment/store', data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    getAttachment(pageNumber=1,course_id, type, month_id=0)
    {
        return axiosIns.get(`/course-attachment/${course_id}/${type}/${month_id}?page=${pageNumber}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    getLoggedInAttachment(type, monthId=null)
    {
        return axiosIns.get(`/loggedIn/course-attachment/${type}/${monthId}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    findCourseAttachment(id)
    {
        return axiosIns.get(`/find-course-attachment/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    editCourseAttachment(id, data)
    {
        return axiosIns.post(`/edit-course-attachment/${id}`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    
    adminMonthlyVideo(id)
    {
        return axiosIns.get(`admin-monthly-video/listing/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    studentMonthlyVideo()
    {
        return axiosIns.get(`monthly-video/listing`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    
    deleteCourseAttachment(data)
    {
        return axiosIns.post(`/course-attachment/delete`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }    
}

export default new CourseService();